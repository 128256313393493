import React from "react";
import styled from "styled-components";

const Moves = ({ selectedMoveType }) => {
  const allMoves = {
    Moves: [
      {
        title: "Quick Punch",
        image: "/assets/img/polybots/icons/Soco rápido.png",
        description:
          "A swift and straightforward punch that allows for quick hits on the opponent. Ideal for wearing down defenses and exploiting openings in the enemy's guard.",
        stats: {
          Cost: "1 Energy",
          Damage: "1 to 5 + Bonus Attributes",
          Effect: "None",
        },
      },
      {
        title: "Quick Shot",
        image: "/assets/img/polybots/icons/Tiro rápido.png",
        description:
          "A rapid shot fired from your Polybot, dealing moderate damage quickly. This move is perfect for maintaining offensive pressure while conserving energy.",
        stats: {
          Cost: "2 Energy",
          Damage: "3 to 9 + Bonus Attributes",
          Effect: "None",
        },
      },
      {
        title: "Strong Punch",
        image: "/assets/img/polybots/icons/Soco forte.png",
        description:
          "A powerful punch that can deal significant damage to the opponent. Use this move to break through tough defenses and deliver a solid blow.",
        stats: {
          Cost: "2 Energy",
          Damage: "1 to 10 + Bonus Attributes",
          Effect: "None",
        },
      },
      {
        title: "Simple Shot",
        image: "/assets/img/polybots/icons/Tiro Simples.png",
        description:
          "A basic but effective shot that packs a punch with higher damage. Ideal for finishing off weakened enemies or dealing consistent damage.",
        stats: {
          Cost: "3 Energy",
          Damage: "5 to 15 + Bonus Attributes",
          Effect: "None",
        },
      },
      {
        title: "Simple Defense",
        image: "/assets/img/polybots/icons/Defesa Simpless.png",
        description:
          "A defensive move that utilizes your Polybot's total defense capability. Best used to block incoming attacks and mitigate damage effectively.",
        stats: {
          Cost: "1 Energy",
          Damage: "Defense: 1 to Max of the Robot + Bonus Attributes",
          Effect: "None",
        },
      },
      {
        title: "Passive Defense: Pass",
        image: "/assets/img/polybots/icons/Defesa Passiva Passe.png",
        description:
          "A passive defense mechanism that offers random defense up to the maximum capability of the robot. Useful for conserving energy while still maintaining some level of protection.",
        stats: {
          Cost: "0 Energy",
          Damage:
            "Defense: Random from 1 to Max of the Robot + Bonus Attributes",
          Effect: "None",
        },
      },
      {
        title: "Thunderous Burst",
        image: "/assets/img/polybots/icons/Explosão estrondosa.png",
        description:
          "A thunder-infused attack that strikes with moderate to high damage. This move can disrupt the opponent's rhythm and cause significant damage.",
        stats: {
          Cost: "3 Energy",
          Damage: "5 to 15 + Bonus Attributes",
          Effect: "None",
        },
      },
      {
        title: "Marine Pulse",
        image: "/assets/img/polybots/icons/Pulso Marinho.png",
        description:
          "A water-based attack that delivers consistent damage to the opponent. Great for exploiting weaknesses in water-vulnerable enemies.",
        stats: {
          Cost: "3 Energy",
          Damage: "5 to 15 + Bonus Attributes",
          Effect: "None",
        },
      },
      {
        title: "Claw Slash",
        image: "/assets/img/polybots/icons/Garra Slash.png",
        description:
          "A ferocious slash with mechanical claws, dealing varying damage. This attack can catch opponents off guard and leave them reeling.",
        stats: {
          Cost: "2 Energy",
          Damage: "1 to 10 + Bonus Attributes",
          Effect: "None",
        },
      },
      {
        title: "Cutting Defense",
        image: "/assets/img/polybots/icons/Defesa de corte.png",
        description:
          "A defensive move that not only protects but also counters with an attack. This move is perfect for turning the tables on an aggressive opponent.",
        stats: {
          Cost: "3 Energy",
          Damage:
            "1 to 10 + Bonus Attributes",
          Effect: "Defense with counterattack",
        },
      },
      {
        title: "Stealth Shot",
        image: "/assets/img/polybots/icons/Tiro furtivo.png",
        description:
          "A sneaky attack that catches opponents off guard with moderate damage. Use this move to surprise your enemies and gain the upper hand.",
        stats: {
          Cost: "3 Energy",
          Damage: "5 to 15 + Bonus Attributes",
          Effect: "None",
        },
      },
      {
        title: "Move Scanner",
        image: "/assets/img/polybots/icons/Mover Scanner.png",
        description:
          "An insightful attack that reveals the opponent's move list while causing damage. This move gives you a strategic advantage by knowing the enemy's next moves.",
        stats: {
          Cost: "2 Energy",
          Damage: "3 to 9 + Bonus Attributes",
          Effect: "Attack that shows the opponent's list of moves",
        },
      },
      {
        title: "Night Burst",
        image: "/assets/img/polybots/icons/Explosão noturna.png",
        description:
          "A dark energy burst that delivers consistent damage in the dark. Perfect for nighttime battles or when visibility is low.",
        stats: {
          Cost: "3 Energy",
          Damage: "5 to 15 + Bonus Attributes",
          Effect: "None",
        },
      },
      {
        title: "Stunning Defense",
        image: "/assets/img/polybots/icons/Defesa impressionante.png",
        description:
          "A defensive move that has a chance to stun the opponent, causing them to lose their next turn. This can provide a crucial advantage in close battles.",
        stats: {
          Cost: "3 Energy",
          Damage: "5 to 12 + Bonus Attributes",
          Effect: "20% chance that the opponent will lose the next Turn",
        },
      },
      {
        title: "Lacerating Claw",
        image: "/assets/img/polybots/icons/wolf 01.png",
        description:
        "A devastating melee attack that slashes with sharp claws, capable of inflicting severe damage on the opponent. While it doesn't deal direct damage, its effectiveness can be felt in prolonged battles.",
        stats: {
          Cost: "2 Energy",
          Damage: "5 to 10 + Bonus Attributes",
          Effect: "None",
        },
      },
      {
        title: "Moonlit Slash",
        image: "/assets/img/polybots/icons/wolf 02.png",
        description:
        "A swift and precise attack that harnesses the light of the moon to inflict a bleed effect on the opponent. This causes ongoing damage over time, potentially turning the tide in a fight.",
        stats: {
          Cost: "3 Energy",
          Damage: "5 to 15 + Bonus Attributes",
          Effect: "Causes Bleed, dealing damage over multiple turns",
        },
      }
      
    ],

    SpecialMoves: [
      {
        title: "Special: Strong Shot",
        image: "/assets/img/polybots/icons/Special Icons/Strong Shot.png",
        description:
          "An advanced shot with enhanced power, delivering high damage. This move is excellent for dealing a heavy blow in critical moments.",
        stats: {
          Cost: "6 Energy",
          Damage: "20 to 25 + Bonus Attributes",
          Effect: "None",
        },
      },
      {
        title: "Special: Scarlet Explosion",
        image: "/assets/img/polybots/icons/Special Icons/Scarlet Explosion.png",
        description:
          "A fiery explosion that deals high damage to the opponent. This move is ideal for dealing massive damage in a single, decisive strike.",
        stats: {
          Cost: "8 Energy",
          Damage: "25 to 30 + Bonus Attributes",
          Effect: "None",
        },
      },
      {
        title: "Special: Oceanic Vortex",
        image: "/assets/img/polybots/icons/Special Icons/Oceanic Vortex.png",
        description:
          "A swirling water attack that delivers substantial damage. Use this move to overwhelm your opponents with the power of the ocean.",
        stats: {
          Cost: "8 Energy",
          Damage: "20 to 32 + Bonus Attributes",
          Effect: "None",
        },
      },
      {
        title: "Special: Ronin's Fury",
        image: "/assets/img/polybots/icons/Special Icons/Ronin's Fury.png",
        description:
          "A powerful and precise attack that deals high damage. This move embodies the spirit of the Ronin, striking with deadly accuracy.",
        stats: {
          Cost: "8 Energy",
          Damage: "27 to 31 + Bonus Attributes",
          Effect: "None",
        },
      },
      {
        title: "Special: Luminar Trap",
        image: "/assets/img/polybots/icons/Special Icons/Luminar Trap.png",
        description:
          "A light-based trap that deals moderate to high damage. Use this move to ensnare your opponents and hit them with a powerful light attack.",
        stats: {
          Cost: "8 Energy",
          Damage: "22 to 28 + Bonus Attributes",
          Effect: "None",
        },
      },
      {
        title: "Special: Phantom Flight",
        image: "/assets/img/polybots/icons/Special Icons/Phantom Flight.png",
        description:
          "An elusive attack that hits the opponent with high damage. This move allows your Polybot to strike swiftly and then vanish like a phantom.",
        stats: {
          Cost: "8 Energy",
          Damage: "21 to 29 + Bonus Attributes",
          Effect: "None",
        },
      },
      {
        title: "Special: Lunar Fang Strike",
        image: "/assets/img/polybots/icons/Special Icons/Lunar.png",
        description:
          "The Wolf chip allows for a powerful strike under the moonlight, delivering a ferocious attack.",
        stats: {
          Cost: "8 Energy",
          Damage: "24 to 33 + Bonus Attributes",
          Effect: "None",
        },
      },
    ],
  };

  const movesToDisplay =
    selectedMoveType === 1 ? allMoves.SpecialMoves : allMoves.Moves;

    return (
      <Container>
        <Title>Polybots Moves</Title>
        {movesToDisplay.map((move, index) => (
          <MoveContainer key={index}>
            <MoveDetails>
              <MoveTitle>{move.title}</MoveTitle>
              <MoveImage src={move.image} alt={move.title} />
            </MoveDetails>
            <MoveStatsContainer>
              <MoveDescription>{move.description}</MoveDescription>
              <MoveStats>
                <HighlightedText>Cost:</HighlightedText> {move.stats.Cost}
              </MoveStats>
              <MoveStats>
                <HighlightedText>Damage:</HighlightedText> {move.stats.Damage}
              </MoveStats>
              {move.stats.Effect !== "None" && (
                <MoveStats>
                  <HighlightedText>Effect:</HighlightedText> {move.stats.Effect}
                </MoveStats>
              )}
            </MoveStatsContainer>
          </MoveContainer>
        ))}
      </Container>
    );
  };

const Container = styled.div`
  padding: 20px;

  @media (max-width: 400px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const HighlightedText = styled.span`
  font-size: 1.1em;
  font-weight: bold;
  color: #40a5f8;
`;

const Title = styled.h1`
  font-size: 3em;
  margin-bottom: 20px;

  @media (max-width: 515px) {
    font-size: 2.5em;
  }

  @media (max-width: 420px) {
    font-size: 2em;
  }

  @media (max-width: 330px) {
    font-size: 1.8em;
  }
`;

const MoveContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 50px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
  }
`;

const MoveDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MoveImage = styled.img`
  width: 200px;
  height: 200px;
  margin-bottom: 10px;
  border: 1px solid  #40a5f8;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const MoveStatsContainer = styled.div`
  margin-left: 20px;
  margin-top: 30px;

  @media (max-width: 768px) {
    margin-left: 0;
    text-align: center;
    margin-top: 20px;
    width: 90%;
  }
`;

const MoveTitle = styled.h2`
  font-size: 1.5em;
  margin-bottom: 10px;
  text-align: center;
`;

const MoveDescription = styled.p`
  font-size: 1em;
  margin-bottom: 10px;

  @media (max-width: 400px) {
    font-size: 0.9em;
  }
`;

const MoveStats = styled.p`
  font-weight: bold;
`;

export default Moves;
