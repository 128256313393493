import React from 'react';
import styled, { keyframes } from 'styled-components';
import { gearsMapping } from '../../pages/profile/Mapping/Mapping';

const Inventory = ({ gears, openModal }) => {
  return (
    <Gears>
                          {gears.map((part, index) => {
                            let robotPart = null;
                            if (gearsMapping[part.id]) {
                              robotPart = gearsMapping[part.id];
                            } else {
                              robotPart =
                                gearsMapping[`${part.id}.${part.stars}`];
                            }
                            if (robotPart) {
                              const backgroundImage =
                                part.stars === 1
                                  ? "url('assets/img/polybots/Gears/bg-gray.png')"
                                  : part.stars === 2
                                  ? "url('assets/img/polybots/Gears/bg-green.png')"
                                  : "url('assets/img/polybots/Gears/bg-purple.png')";
                              return (
                                <>
                                  <div
                                    key={part.partId}
                                    className="background"
                                    style={{
                                      marginBottom: "10px",
                                      backgroundImage: backgroundImage,
                                      backgroundSize: "cover",
                                      backgroundPosition: "center",
                                    }}
                                  >
                                    <img
                                      key={index}
                                      src={robotPart.image}
                                      style={{
                                        left: part.minted ? "10px" : "initial", // Aplica left: 10px se part.minted for true
                                        position: part.minted
                                          ? "relative"
                                          : "initial", // Garante que left funcione aplicando position: relative
                                      }}
                                      onClick={() => {
                                        openModal(
                                          robotPart,
                                          backgroundImage,
                                          part
                                        );
                                      }}
                                    />
                                    {part.minted && (
                                      <div
                                        style={{
                                          position: "relative",
                                        }}
                                      >
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: "10px",
                                            right: "12px",
                                            width: "25px",
                                            height: "25px",
                                            backgroundImage:
                                              "url('assets/img/moonbeam.png')",
                                            backgroundSize: "contain", // Faz a imagem se adaptar ao tamanho da div
                                            backgroundPosition: "center", // Centraliza a imagem
                                            backgroundRepeat: "no-repeat", // Evita que a imagem se repita
                                            borderRadius: "100%",
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </>
                              );
                            }
                            return null;
    })}
  </Gears>
  );
};

export const growAnimation = keyframes`
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const Gears = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px;
  padding: 15px;
  align-items: flex-start;
  width: 100%;
  height: calc(
    100% - 14% - 10px
  ); // 100% - 14% (back button size) - 10px (margin bottom back button)
  overflow-y: auto;
  overflow-x: hidden;
  background: rgba(1, 31, 47, 0.8);
  border-radius: 10px;
  border-left: 3px solid #00bfff;
  border-right: 3px solid #00bfff;
  border-top: 1px solid #00bfff;
  border-bottom: 1px solid #00bfff;
  animation: ${growAnimation} 0.1s ease-in-out;

  div {
    width: 120px;
    height: 120px;
  }

  img {
    width: 100px;
    height: 100px;
  }

  @media (max-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 770px) {
    div {
      width: 80px;
      height: 80px;
    }
    img {
      width: 60px;
      height: 60px;
    }
  }

  @media (max-width: 550px) {
    grid-template-columns: repeat(2, 1fr);

    div {
      width: 120px;
      height: 120px;
      margin-inline: auto;
    }

    img {
      width: 100px;
      height: 100px;
    }
  }

  @media (max-width: 500px) {
    gap: 5px;
  }

  @media (max-width: 450px) {
    padding: 10px;

    div {
      width: 110px;
      height: 110px;
      margin-inline: auto;
    }

    img {
      width: 90px;
      height: 90px;
    }
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #273444;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #00bfff;
    border-radius: 10px;
    border: 2px solid #273444;
  }

  scrollbar-width: thin;
  scrollbar-color: #3498db #273444;

  .background {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      transition: 0.2s;
      transform: scale(1.05);
    }
  }
`;


export default Inventory;