import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Popup,
  CloseButton,
  PopupContent,
  Overlay,
  CardWrapper,
  CardBackground,
  CardContent,
  CardContainer,
  CardGlow,
  AttributesContainer,
  growAnimation,
} from "./mintModalcss";

import Stepper from "../../Stepper/Stepper";
import { getItemAttributesWithBonus } from "../../../pages/profile/func/AttributesCalc";
import ConfettiComponent from "../../confetti/Confetti";
import CheckIcon from "./icons/check";
import { Buffer } from "buffer";

export default function MintModal({
  closeModal,
  gearsExibition,
  gearsData,
  bgimg,
  jwt,
  attFarmPoints,
  gems,
  farmPoints,
  profileData,
  connectWallet,
}) {
  const [serial, setSerial] = useState(null);
  const [mintStatus, setMintStatus] = useState(null);
  const [attributes, setAttributes] = useState(null);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(true);
  const [isMinting, setIsMinting] = useState(false);
  const [successMint, setSuccessMint] = useState(false);
  const [successWithdraw, setSuccessWithdraw] = useState(false);
  const [hash, setHash] = useState(null);

  const [closeNetworkHandler, setCloseNetwork] = useState(null);
  const [popUpWithdraw, setPopUpWithdraw] = useState(false);
  const [popUpNetwork, setPopUpNetwork] = useState(false);
  const [confirmNetwork, setConfirmNetwork] = useState(null);
  const [networkSelected, setNetworkSelected] = useState(null);

  const [sliceWallet, setSlicedWallet] = useState(null);

  const close = () => {
    closeModal(true);
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    const attributes = getItemAttributesWithBonus(
      gearsData.stars,
      gearsData.partId,
      gearsData.id
    );
    setAttributes(attributes);
  }, []);

  useEffect(() => {
    const getPartData = async () => {
      try {
        const responseMintStatus = await fetch(
          `https://us-central1-polybots-d02df.cloudfunctions.net/getMintStatus?partId=${gearsData.partId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${jwt}`,
            },
          }
        );

        setStep(2);

        const responseGears = await fetch(
          `https://us-central1-polybots-d02df.cloudfunctions.net/filteredPartRanking?partId=${gearsData.partId}&partSpecificId=${gearsData.id}`,
          {
            method: "GET",
          }
        );

        // Verifica se a resposta foi bem-sucedida
        if (!responseGears.ok) {
          throw new Error(`Erro na requisição: ${responseGears.status}`);
        }
        if (!responseMintStatus.ok) {
          throw new Error(`Erro na requisição: ${responseGears.status}`);
        }

        setStep(3);

        const data = await responseGears.json(); // Converte a resposta em JSON
        const dataMintStatus = await responseMintStatus.json();
        setMintStatus(dataMintStatus);

        setTimeout(() => {
          setStep(4);
        }, 500);

        setTimeout(() => {
          setSerial(data.rank);
        }, 1200);

        setLoading(false);
      } catch (error) {
        console.error("Erro ao realizar a requisição:", error);
      }
    };

    getPartData();
  }, []);

  useEffect(() => {
    if (profileData.wallet) {
      const fullText = profileData.wallet;
      const shortenedText = fullText.slice(0, 5) + "..." + fullText.slice(-5);
      setSlicedWallet(shortenedText);
    }
  }, [profileData]);

  const handleMint = async (event) => {
    event.stopPropagation();
    if (serial && mintStatus && !isMinting) {
      try {
        const selectedNetwork = await openPopUpWaitNetwork();

        if (selectedNetwork) {
          setIsMinting(true);

          const firstSerial = serial.split("/")[0];
          const gearsType = gearsExibition.name.split(" ")[1];

          const body = {
            partId: gearsData.partId, // A unique identifier for the part.
            network: "moonbeam", // The network name.
            partDetails: {
              id: gearsData.id, // The unique ID for this part.
              type: gearsType, // The part type (e.g., "head", "body", "legs").
              attack: attributes.attack, // The attack value (numeric).
              defense: attributes.defense, // The defense value (numeric).
              health: attributes.health, // The health value (numeric).
              agility: attributes.agility, // The agility value (numeric).
              energy: attributes.energy, // The energy value (numeric).
              stars: gearsData.stars, // The star rating (numeric).
              name: gearsExibition.imgName, // The name of the part (e.g., "YUT001").
              displayName: gearsExibition.name, // (Optional) Display name of the part.
              serial: firstSerial, // (Optional) A unique serial number for this part.
            },
          };

          // const encodedBody = btoa(JSON.stringify(body)); // Encode the body as base64
          const encodedBody = Buffer.from(JSON.stringify(body)).toString(
            "base64"
          );

          try {
            const responseMintStatus = await fetch(
              `https://us-central1-polybots-d02df.cloudfunctions.net/mintPartTest`,
              {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${jwt}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ data: encodedBody }), // Send the encoded body
              }
            );

            const mintData = await responseMintStatus.json();

            setHash(mintData.hash);

            gearsData.minted = true;

            attFarmPoints(true);
            setSuccessMint(true);
          } catch (error) {
            setIsMinting(false);
            console.log(`Unable to mint: ${error.message} - ${error.cause}`);
          }
        }
      } catch (error) {
        setIsMinting(false);
        console.log("Mint cancelled or no network selected");
      }
    } else {
      console.log("wait verifications, mint not available");
    }
  };

  const handleOpenWithdraw = (event) => {
    event.stopPropagation();

    if (serial && mintStatus) {
      setPopUpWithdraw(true);
    } else {
      console.log("wait verifications, withdraw not available");
    }
  };

  const closeWithdraw = () => {
    setPopUpWithdraw(false);
  };

  const handleWithdraw = () => {
    profileData.balanceGem -= 20;
    setSuccessWithdraw(true);
    closeWithdraw();
  };

  const handleView = (event) => {
    event.stopPropagation();
    window.open(`https://moonscan.io/tx/${hash}`);
  };

  const openPopUpWaitNetwork = () => {
    return new Promise((resolve, reject) => {
      setPopUpNetwork(true);

      const handleNetworkSelection = (network) => {
        console.log("Network selected:", network);
        setPopUpNetwork(false);
        resolve(network);
      };

      const handleCloseNetwork = () => {
        setPopUpNetwork(false);
        reject(new Error("Mint cancelled or no network selected"));
      };

      setNetworkSelected(null);
      setCloseNetwork(() => handleCloseNetwork);
      setConfirmNetwork(() => handleNetworkSelection);
    });
  };

  const closeNetwork = () => {
    if (typeof closeNetworkHandler === "function") {
      closeNetworkHandler();
    }
  };

  const handleChangeNetwork = (e) => {
    const network = e.target.value;
    setNetworkSelected(network);
  };

  const handleConfirmNetwork = () => {
    if (networkSelected && typeof confirmNetwork === "function") {
      confirmNetwork(networkSelected);
    }
  };

  const handleUserWithoutWallet = () => {
    connectWallet(true);
    close();
  };

  const isChip = gearsExibition.name.includes("Chip");

  return (
    <>
      <Overlay onClick={close} />
      <ConfettiComponent active={successMint} repeat={1} />

      {popUpNetwork && (
        <>
          <OverlayNetwork onClick={closeNetwork} />
          <PopUpNetwork>
            <ContentNetwork>
              <CloseButton onClick={closeNetwork}>×</CloseButton>
              <div
                style={{
                  backgroundImage: `${bgimg}`,
                  backgroundSize: "cover",
                  width: "180px",
                  height: "180px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginInline: "auto",
                }}
              >
                <img width="150" height="150" src={gearsExibition.image}></img>
              </div>
              <h5>Cost: 10FP</h5>
              <SelectContainerNetwork>
                <StyledSelectNetwork
                  onChange={handleChangeNetwork}
                  defaultValue=""
                >
                  <option value="" disabled>
                    Select Network
                  </option>
                  <option value="moonbeam">Moonbeam</option>
                  {/* Adicione outras opções conforme necessário */}
                </StyledSelectNetwork>
              </SelectContainerNetwork>
              {networkSelected === "moonbeam" &&
                (farmPoints >= 10 ? (
                  <CardContainer
                    onClick={handleConfirmNetwork}
                    style={{
                      cursor: "pointer",
                      marginInline: "auto",
                      marginTop: "10px",
                    }}
                  >
                    <CardContent>Confirm</CardContent>
                    <CardBackground />
                    <CardGlow />
                  </CardContainer>
                ) : (
                  <>
                    <CardContainer
                      style={{
                        cursor: "not-allowed",
                        marginInline: "auto",
                        marginTop: "10px",
                        color: "#808080",
                      }}
                    >
                      <CardContent>Confirm</CardContent>
                    </CardContainer>
                    <h5>
                      Not enough{" "}
                      <span style={{ color: "#00bfff" }}>Farm Points</span>
                    </h5>
                  </>
                ))}
            </ContentNetwork>
          </PopUpNetwork>
        </>
      )}

      {popUpWithdraw && (
        <>
          <OverlayNetwork onClick={closeWithdraw} />
          <PopUpNetwork style={{ height: "500px" }}>
            <ContentNetwork>
              <CloseButton onClick={closeWithdraw}>×</CloseButton>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "#00bfff",
                }}
              >
                <h2 style={{ fontSize: "30px", color: "white" }}>
                  {gearsExibition.name}
                </h2>

                {serial}
              </div>
              <div
                style={{
                  backgroundImage: `${bgimg}`,
                  backgroundSize: "cover",
                  width: "180px",
                  height: "180px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginInline: "auto",
                }}
              >
                <img width="150" height="150" src={gearsExibition.image}></img>
              </div>
              <h5>
                Cost: 20{" "}
                <img
                  src={"/assets/img/icon/gameIcons/Gem.png"}
                  style={{ width: "20px", height: "20px" }}
                />
              </h5>
              <h6>
                Destiny Wallet:{" "}
                <span className="walletAddress">{sliceWallet}</span>
              </h6>
              <h6>
                Network: <span className="walletAddress">Moonbeam</span>
              </h6>
              {gems >= 20 ? (
                <CardContainer
                  onClick={handleWithdraw}
                  style={{
                    cursor: "pointer",
                    marginInline: "auto",
                    marginTop: "10px",
                  }}
                >
                  <CardContent>Confirm</CardContent>
                  <CardBackground />
                  <CardGlow />
                </CardContainer>
              ) : (
                <>
                  <CardContainer
                    style={{
                      cursor: "not-allowed",
                      marginInline: "auto",
                      marginTop: "10px",
                      color: "#808080",
                    }}
                  >
                    <CardContent>Confirm</CardContent>
                  </CardContainer>
                  <h5>
                    Not enough <span style={{ color: "#e159c9" }}>Gems</span>
                  </h5>
                </>
              )}
            </ContentNetwork>
          </PopUpNetwork>
        </>
      )}

      <Popup className={isChip ? "shortSize" : ""}>
        <CloseButton onClick={closeModal}>×</CloseButton>
        <PopupContent>
          <div>
            {serial ? (
              <div
                style={{
                  height: "7rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  color: "#00bfff",
                }}
              >
                <h2 style={{ fontSize: "30px", color: "white" }}>
                  {gearsExibition.name}
                </h2>

                {serial}
              </div>
            ) : (
              // Loading data
              <Stepper currentStep={step} />
            )}
          </div>
          <div
            style={{
              backgroundImage: `${bgimg}`,
              backgroundSize: "cover",
              width: "180px",
              height: "180px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "-1.5rem",
            }}
          >
            <img width="150" height="150" src={gearsExibition.image}></img>
          </div>
          {/* Default screen, being showned when Mint was not called */}
          {!successMint && !successWithdraw ? (
            <>
              {/* Chips doesn't hava attributes so we don't need to show */}
              {!isChip && attributes && (
                <AttributesContainer>
                  <div>
                    <h6>Attack:</h6>
                    <h6 className="blue">{attributes.attack}</h6>
                  </div>
                  <div>
                    <h6>Defense:</h6>
                    <h6 className="blue">{attributes.defense}</h6>
                  </div>
                  <div>
                    <h6>Health:</h6>
                    <h6 className="blue">{attributes.health}</h6>
                  </div>
                  <div>
                    <h6>Agility:</h6>
                    <h6 className="blue">{attributes.agility}</h6>
                  </div>
                  <div>
                    <h6>Energy:</h6>
                    <h6 className="blue">{attributes.energy}</h6>
                  </div>
                </AttributesContainer>
              )}
              {/* Default screen, when part is not minted */}
              {!gearsData.minted ? (
                <CardWrapper>
                  {serial ? (
                    <CardContainer
                      onClick={(event) => !isMinting && handleMint(event)}
                      style={{ cursor: isMinting ? "not-allowed" : "pointer" }}
                    >
                      <CardContent>
                        {isMinting ? "Minting..." : "Mint"}
                      </CardContent>
                      <CardBackground />
                      <CardGlow />
                    </CardContainer>
                  ) : (
                    <CardContainer
                      onClick={(event) => handleMint(event)}
                      style={{ cursor: "wait" }}
                    >
                      <CardContent style={{ color: "#808080" }}>
                        Mint
                      </CardContent>
                    </CardContainer>
                  )}
                </CardWrapper>
              ) : (
                <></>
                // When the part is minted
                //   <CardWrapper>
                //     {serial ? (
                //       profileData?.wallet !== "none" || profileData?.wallet !== null ? (
                //         <CardContainer
                //           onClick={(event) => handleOpenWithdraw(event)}
                //           style={{ cursor: "pointer" }}
                //         >
                //           <CardContent>Withdraw</CardContent>
                //           <CardBackground />
                //           <CardGlow />
                //         </CardContainer>
                //       ) : (
                //         <>
                //           <CardContainer style={{ cursor: "not-allowed", marginInline: "auto" }}>
                //             <CardContent style={{ color: "#808080" }}>Withdraw</CardContent>
                //           </CardContainer>
                //           <p onClick={handleUserWithoutWallet} style={{ color: "#00bfff", marginTop: "10px", textAlign: "center", textDecoration: "underline", cursor: "pointer" }}>
                //             Wallet connection required
                //           </p>
                //         </>
                //       )
                //     ) : (
                //       <CardContainer
                //         style={{ cursor: "wait" }}
                //       >
                //         <CardContent style={{ color: "#808080" }}>
                //           Withdraw
                //         </CardContent>
                //       </CardContainer>
                //     )}
                //   </CardWrapper>
              )}
            </>
          ) : (
            // When the part was minted in that moment and was a success
            <>
              <h2
                style={{
                  margin: "0 20px",
                  background: "#032054",
                  padding: "20px",
                  borderRadius: "10px",
                  marginBottom: "10px",
                }}
              >
                Your{" "}
                <span style={{ color: "#00bfff" }}>
                  {successMint ? "Mint" : "Withdraw"}
                </span>{" "}
                Was a Success
                <CheckIcon />
              </h2>
              <CardWrapper>
                {successMint && hash ? (
                  <CardContainer
                    onClick={(event) => handleView(event)}
                    style={{ cursor: "pointer" }}
                  >
                    <CardContent>View</CardContent>
                    <CardBackground />
                    <CardGlow />
                  </CardContainer>
                ) : successWithdraw ? (
                  <CardContainer style={{ cursor: "not-allowed" }}>
                    <CardContent style={{ color: "#808080" }}>View</CardContent>
                  </CardContainer>
                ) : (
                  <CardContainer
                    onClick={(event) => handleView(event)}
                    style={{ cursor: "wait" }}
                  >
                    <CardContent style={{ color: "#808080" }}>View</CardContent>
                  </CardContainer>
                )}
              </CardWrapper>
            </>
          )}
        </PopupContent>
      </Popup>
    </>
  );
}

const OverlayNetwork = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 902;
  cursor: pointer;
`;

const PopUpNetwork = styled.div`
  z-index: 903;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 425px;
  background: URL("assets/img/bg/mint-bg.png") no-repeat center center;
  border: 3px solid rgba(0, 191, 255, 0.9);
  box-shadow: rgba(0, 191, 255, 0.6) 0px 2px 8px 0px;
  padding: 20px; /* Espaçamento interno */
  border-radius: 10px; /* Bordas arredondadas */
`;

const ContentNetwork = styled.div`
  width: 100%;
  height: 100%;
  animation: ${growAnimation} 0.1s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  .walletAddress {
    color: #00bfff;
  }
`;

const SelectContainerNetwork = styled.div``;

const StyledSelectNetwork = styled.select`
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc; /* Borda cinza */
  font-size: 16px; /* Tamanho da fonte */
  width: 200px; /* Largura do select */
  border: none;
  background: #032054;
  color: white;
`;
