import React from "react";
import styled from "styled-components";

const Chips = ({ selectedChip, setSelectedChip }) => {
  const chips = [
    {
      name: "Insect",
      image: "/assets/img/polybots/Medals/Wasp/medal_wasp_gold.png",
      cost: "60 Energy",
      damage: "20 to 25 + Bonus Atributes",
      special: "Strong Shot",
      description:
        "The Insect chip unleashes a precise and relentless attack, mimicking the persistent and focused nature of its namesake.",
    },
    {
      name: "Phoenix",
      image: "/assets/img/polybots/Medals/Fenix/medal_fenix gold.png",
      cost: "80 Energy",
      damage: "25 to 30 + Bonus Atributes",
      special: "Scarlet Explosion",
      description:
        "Like a fiery rebirth, the Phoenix chip engulfs enemies in a blazing assault, symbolizing the creature's legendary resurrection.",
    },
    {
      name: "Tanuki",
      image: "/assets/img/polybots/Medals/Tanuki/medal_tanuki gold.png",
      cost: "80 Energy",
      damage: "22 to 28 + Bonus Atributes",
      special: "Lumiar Trap",
      description:
        "Channeling the trickster spirit of the Tanuki, this chip creates a dazzling diversion, catching foes off guard with its mystical energy.",
    },
    {
      name: "Tiger",
      image: "/assets/img/polybots/Medals/Tiger/medal_tiger gold.png",
      cost: "80 Energy",
      damage: "27 to 31 + Bonus Atributes",
      special: "Ronin's Fury",
      description:
        "With the ferocity of a Tiger, this chip delivers a powerful strike, embodying the raw strength and swift justice of a lone warrior.",
    },
    {
      name: "Turtle",
      image: "/assets/img/polybots/Medals/Turtle/medal_turtle gold.png",
      cost: "80 Energy",
      damage: "20 to 32 + Bonus Atributes",
      special: "Oceanic Vortex",
      description:
        "Harnessing the Turtle's wisdom and patience, this chip releases a deep, swirling force, pulling enemies into a slow, inevitable defeat.",
    },
    {
      name: "Moth",
      image: "/assets/img/polybots/Medals/Moth/medal_vesp gold.png",
      cost: "80 Energy",
      damage: "21 to 29 + Bonus Atributes",
      special: "Phantom Flight",
      description:
        "The Moth chip strikes swiftly and silently, much like its namesake, delivering a deadly blow with unmatched speed and precision.",
    },
    {
      name: "Wolf",
      image: "/assets/img/polybots/Medals/Wolf/medal_wolf gold.png", 
      cost: "80 Energy",
      damage: "",
      special: "Lunar Fang Strike",
      description:
        "The Wolf chip allows for a powerful strike under the moonlight, delivering a ferocious attack.",
    }
    
  ];

  const handleChipClick = (index) => {
    if (selectedChip === index) {
      setSelectedChip(null);
    } else {
      setSelectedChip(index);
    }
  };

  return (
    <Container>
      {selectedChip !== null ? (
        <ChipsContainer>
          <MarginDiv>
            <ChipsName>{chips[selectedChip].name}</ChipsName>
            <ChipsImage
              src={chips[selectedChip].image}
              alt={chips[selectedChip].name}
            />
          </MarginDiv>
          <ChipsDetails>
            <ChipsInfo>
              <HighlightedText>Special: </HighlightedText>
              {chips[selectedChip].special}
            </ChipsInfo>
            <ChipsInfo>
              <HighlightedText>Cost: </HighlightedText>
              {chips[selectedChip].cost}
            </ChipsInfo>
            <ChipsInfo>
              <HighlightedText>Damage: </HighlightedText>
              {chips[selectedChip].damage}
            </ChipsInfo>
            <ChipsInfo>
              <HighlightedText>Description: </HighlightedText>
              {chips[selectedChip].description}
            </ChipsInfo>
          </ChipsDetails>
        </ChipsContainer>
      ) : (
        <Container>
          <Title>Special Abilities with Chips</Title>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={`/assets/img/wiki/Medals.png`}
              alt="Mythical Powers"
              style={{ width: "100%", marginBottom: "30px", minWidth: "280px" }}
            />
            <p>
              Special abilities can be acquired using different chips, which are
              also integral to the assembly of your Polybot. There are three
              levels of chips: silver, bronze, and gold. Each level of chip
              grants access to unique abilities and enhancements, allowing for
              further customization and strategic depth.
            </p>
            <p>
              Bronze chips provide basic enhancements, silver chips offer
              advanced capabilities, and gold chips unlock the most powerful and
              rare abilities, making your Polybot a true force to be reckoned
              with.
            </p>
            <p>
              Discover a whole new level of excitement with animal-themed chips!
              Each chip is related to a different animal and imbued with unique
              abilities. Whether it's the cunning of the fox, the strength of
              the tiger, or the resilience of the turtle, these chips add a
              thrilling layer of strategy and customization to your Polybot.
              Explore, collect, and dominate the battlefield with the power of
              the animal kingdom at your fingertips!
            </p>
          </div>
          <Title style={{ marginTop: "60px" }}>Polybots Chips</Title>
          {chips.map((chip, index) => (
            <ChipsContainer key={index} onClick={() => handleChipClick(index)}>
              <MarginDiv>
                <ChipsName>{chip.name}</ChipsName>
                <ChipsImage src={chip.image} alt={chip.name} />
              </MarginDiv>
              <ChipsDetails>
                <ChipsInfo>
                  <HighlightedText>Special: </HighlightedText>
                  {chip.special}
                </ChipsInfo>
                <ChipsInfo>
                  <HighlightedText>Cost: </HighlightedText>
                  {chip.cost}
                </ChipsInfo>
                <ChipsInfo>
                  <HighlightedText>Damage: </HighlightedText>
                  {chip.damage}
                </ChipsInfo>
                <ChipsInfo>
                  <HighlightedText>Description: </HighlightedText>
                  {chip.description}
                </ChipsInfo>
              </ChipsDetails>
            </ChipsContainer>
          ))}
        </Container>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 20px;

  @media (max-width: 400px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const Title = styled.h1`
  font-size: 3em;
  margin-bottom: 20px;

  @media (max-width: 515px) {
    font-size: 2.5em;
  }

  @media (max-width: 420px) {
    font-size: 2em;
  }

  @media (max-width: 330px) {
    font-size: 1.8em;
  }
`;

const ChipsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
  align-items: center;
  justify-content: center;


  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
  }
`;

const MarginDiv = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const ChipsImage = styled.img`
  width: 200px;
  height: 200px;
  border-radius: 10px;
`;

const ChipsDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: -70px;
  margin-top: 100px;

  @media (max-width: 990px) {
    margin-left: -30px;
}
  @media (max-width: 768px) {
    margin-left: 0;
    text-align: center;
    margin-top: 10px;
  }
`;

const ChipsName = styled.h2`
  font-size: 1.5em;
  margin: 10px 0;
`;

const ChipsInfo = styled.p`
  font-size: 1em;
  margin: 3px 0;

  @media (max-width: 400px) {
    font-size: 0.9em;
    margin: 0px 0;
  }
`;

const HighlightedText = styled.span`
  font-size: 1.1em;
  font-weight: bold;
  color: #40a5f8;
`;

export default Chips;
